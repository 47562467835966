<template>
  <v-container>
    <div>
      <s-crud
        title="Mantenimiento Malla"
        :filter="filter"
        searchInput
        add
        remove
        @save="save($event)"
        edit
        :config="config">

        <template v-slot:filter>
          <v-container>
            <v-row justify="center">
              <v-col cols="4">
                <s-date
                  v-model="filter.DateBegin" 
                  label="Fecha Inicio">
                </s-date>
              </v-col>
              <v-col cols="4">
                <s-date
                  v-model="filter.DateEnd"  
                  label="Fecha Fin">
                </s-date>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template scope="props">
          <v-container>
            <v-row>
              <v-col>
                <s-select-definition
                  label="Destino"
                  v-model="props.item.TypeDestiny"
                  :def="1181">
                </s-select-definition>
              </v-col>
              <v-col>
                <s-select-definition
                  label="Cultivo"
                  :def="1173"
                  v-model="props.item.TypeCultive">
                </s-select-definition>
              </v-col>
              <v-col>
                <s-select-variety
                  full
                  clearable
                  :cultiveID="props.item.TypeCultive"
                  v-model="props.item.VrtID"
                  label="Variedad">
                </s-select-variety>
              </v-col>
              <v-col>
                <s-date v-model="props.item.PrmDate" label="Fecha"> </s-date>
              </v-col>
              <v-col>
                <s-select-definition
                  label="Tipo Cultivo"
                  :def="1172"
                  v-model="props.item.TypeCrop">
                </s-select-definition>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <s-textarea
                  v-model="props.item.PrmDescription"
                  label="Descripción">
                </s-textarea>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </s-crud>
    </div>
  </v-container>
</template>

<script>
import _sMaintenanceGeneral from "@/services/FreshProduction/MaintenanceGeneralService";
import SSelectVariety from "@/components/HarvestSchedule/SSelectVariety";

export default {
  props: {},
  components: { SSelectVariety },
  data() {
    return {
      filter: { DateBegin:null, DateEnd:null, TypeMaintenance: 3},
      config: {
        service: _sMaintenanceGeneral,
        model: {
          PrmID:"ID",
          PrmDate:"date",
        },
        headers: [
          { text: "cod", value: "PrmID" },
          { text: "Destino", value: "TypeDestinyName" },
          { text: "Fecha", value: "PrmDate" },
          { text: "Tipo Cultivo", value: "TypeCropName" },
          { text: "Cultivo", value: "TypeCultiveName" },
          { text: "Variedad", value: "VrtName" },
          { text: "Descripción", value: "PrmDescription" },
        ],
      },
    };
  },

  methods: {
    save(item) {

      item.TypeMaintenance = 3;
      item.UsrUpdateID = this.$fun.getUserID();
      item.UsrCreateID = this.$fun.getUserID();

      item.save();
    },
  },
};
</script>